import Header from "./Header";
import Footer from "./Footer";
import ConForm from "./ConForm";
import DeviceList from "./DeviceList";
import img1 from "./img/IMG_20250326_161654_330.jpg";
import "./Frontpage.css";

const SmallEquipment = () => {
    //!!! banner grid not working quite as intended !!!
    return (
        <div className="background">
            <Header/>
            <div className="rowDiv">
                <div className="bg-text">
                <div className="text-container-grid">
                    <div className="slogan">Vuokraa laitteet tapahtumaasi Luminarylta!</div>
                    <br/>
                    <div className="textblock">Kevyempiin tapahtumakokonaisuuksiin tarjoamme laadukasta ja joustavasti hinnoiteltua pienlaitteistoa, joka sopii erilaisiin tapahtumiin, kuten häihin, juhliin ja yritystilaisuuksiin. 
                    Voit vuokrata juuri tarvitsemasi laitteet, kuten äänentoiston tai valaistuksen laitekohtaisella hinnoittelulla.
                        <br/>
                        <br/>
                        Teknikkomme auttaa sinua valitsemaan yksittäisiä laitteita tai kokonaisuuksia tarpeidesi mukaan – saat kustannustehokkaan ja toimivan ratkaisun ilman ylimääräisiä kuluja.
                        <br/>
                        
                    </div>
                    <br/>
                    <div className="subquestion">Kysy lisää ja räätälöidään yhdessä täydellinen laitteistopaketti tapahtumaasi!</div>
                </div>
                </div>
                <div className="image-container">
                    <img src={img1} width="500" alt="dj" className="LargeEquipmentImage"/>
                    </div>
            </div>
            <ConForm/>

            <Footer/>
        </div>
    );
};

export default SmallEquipment;
